import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";

import LoginPage from "./pages/auth/LoginPage";
import Rootlayout from "../src/pages/home/Rootlayout";
import SamplesValidation from "./pages/home/SamplesValidation";

import ShipmentPage from "./pages/ShipmentPage";
import ShipmentDetail from "./pages/ShipmentDetail";
import LotsPage from "./pages/LotsPage";
import SampleDetailPage from "./pages/SampleDetailPage";

const usertoken = localStorage.getItem("usertoken");

function RedirectComponent() {
  const navigate = useNavigate();
  useEffect(() => {
    if (usertoken) {
      navigate("/samples");
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return null;
}

// function UnknownPages() {
//   const navigate = useNavigate();
//   useEffect(() => {
//     navigate("/home");
//   }, [navigate]);

//   return null;
// }

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RedirectComponent />,
      // errorElement: <UnknownPages />,
    },
    {
      path: "/workflows",
      element: <Rootlayout />,
      children: [{ index: true, element: <SamplesValidation /> }],
    },
    {
      path: "/samples",
      element: <Rootlayout />,
      children: [{ index: true, element: <LotsPage /> }],
    },
    {
      path: "/shipments",
      element: <Rootlayout />,
      children: [{ index: true, element: <ShipmentPage /> }],
    },
    {
      path: "/sample/:sampleId/:shipmentId",
      element: <Rootlayout />,
      children: [{ index: true, element: <SampleDetailPage /> }],
    },
    {
      path: "/shipment/:shipmentId",
      element: <Rootlayout />,
      children: [{ index: true, element: <ShipmentDetail /> }],
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
