import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  LinearProgress,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import HttpService from "../services/http.service";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { drxnaUrl } from "../services/url.service";

const ShipmentPage = () => {
  const navigate = useNavigate();
  const [shipments, setShipments] = useState([]);

  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState("");
  const [page, setPage] = useState(1);

  const getAllShipments = (page) => {
    setLoading(true);
    HttpService.get(`${drxnaUrl}/shipment/?page=${page}&limit_per_page=11`, {})
      .then((res) => {
        const reversed = res?.data?.data;
        setShipments(reversed);

        setTotalPages(res?.data?.metadata?.pagination?.total_pages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllShipments(page);
  }, [page]);

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [shipmentName, setShipmentName] = useState("");

  const creatShipment = () => {
    setLoading(true);
    HttpService.post(`${drxnaUrl}/shipment/`, {
      name: shipmentName,
    })
      .then((res) => {
        console.log(res?.data);
        setShipments((prev) => {
          return [res?.data?.data, ...prev];
        });
        setOpenCreateModal(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const cancelShipment = (id) => {
    setLoading(true);
    HttpService.remove(`${drxnaUrl}/shipment/${id}`)
      .then((res) => {
        setShipments((prevMessages) =>
          prevMessages.map((shipment) =>
            shipment._id === id
              ? { ...shipment, shipment_status: "cancelled" }
              : shipment
          )
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Box sx={{ height: "100%" }}>
      {loading && <LinearProgress />}
      <Box sx={{ p: 5 }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "600",
              color: "#1e293b",
            }}
          >
            Shipments
          </Typography>
          <Button
            sx={{
              textTransform: "none",
              background: "#1e5267",
              ":hover": { background: "#1e5267" },
            }}
            onClick={() => {
              setOpenCreateModal(true);
            }}
            variant="contained"
          >
            New Shipment
          </Button>
        </Box>

        {shipments?.length !== 0 && (
          <TableContainer
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              height: "73vh",
              mb: 2,
            }}
          >
            <Table>
              <TableHead
                sx={{
                  borderBottom: "1px solid #e2e8f0",
                  position: "sticky",
                  top: 0,
                  left: 0,
                  background: "#fff",
                }}
              >
                <TableRow>
                  <TableCell sx={{ borderRight: "1px solid #e2e8f0" }}>
                    <Typography
                      variant="body2"
                      fontWeight={"600"}
                      sx={{ cursor: "pointer" }}
                    >
                      Shipment Name
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #e2e8f0" }}>
                    <Typography
                      variant="body2"
                      fontWeight={"600"}
                      sx={{ cursor: "pointer" }}
                    >
                      Shipment ID
                    </Typography>
                  </TableCell>

                  <TableCell sx={{ borderRight: "1px solid #e2e8f0" }}>
                    <Typography
                      variant="body2"
                      fontWeight={"600"}
                      sx={{ cursor: "pointer" }}
                    >
                      Shipment Status
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ borderRight: "1px solid #e2e8f0" }}>
                    <Typography
                      variant="body2"
                      fontWeight={"600"}
                      sx={{ cursor: "pointer" }}
                    >
                      Actions
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ overflowY: "auto" }}>
                {shipments?.length !== 0 &&
                  shipments?.map((shipment, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor:
                          index % 2 === 0 ? "#FFFFFF" : "#fff3eb", // even row color
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(`/shipment/${shipment?._id}`);
                      }}
                    >
                      <TableCell
                        sx={{
                          borderRight: "1px solid #e2e8f0",
                          borderBottom: "none",
                          py: 0,
                          px: "16px",
                        }}
                      >
                        <Box sx={{}}>
                          <Typography>{shipment?.name}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: "1px solid #e2e8f0",
                          borderBottom: "none",
                          px: "16px",
                        }}
                      >
                        <Box sx={{}}>
                          <Typography>{shipment?._id}</Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: "1px solid #e2e8f0",
                          borderBottom: "none",
                          py: 0,
                          px: "16px",
                        }}
                      >
                        <Box sx={{}}>
                          <Typography
                            sx={{
                              color:
                                shipment?.shipment_status === "cancelled"
                                  ? "red"
                                  : shipment?.shipment_status === "delivered"
                                  ? "#22c55e"
                                  : shipment?.shipment_status === "enroute"
                                  ? "#0ea5e9"
                                  : "#000",
                            }}
                          >
                            {shipment?.shipment_status}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: "1px solid #e2e8f0",
                          borderBottom: "none",
                          py: 0,
                          px: "16px",
                        }}
                      >
                        <Box sx={{}}>
                          {shipment?.shipment_status !== "cancelled" &&
                            shipment?.shipment_status !== "delivered" && (
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  cancelShipment(shipment?._id);
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            page={page}
            onChange={(e, p) => {
              setPage(p);
            }}
            variant="outlined"
            shape="rounded"
          />
        )}

        <Dialog
          fullWidth={true}
          sx={{ height: 450 }}
          maxWidth={"xs"}
          open={openCreateModal}
          onClose={() => {
            setOpenCreateModal(false);
          }}
        >
          <DialogTitle>
            <Typography>Create Shipment</Typography>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Input
                sx={{ width: "100%" }}
                onChange={(e) => {
                  setShipmentName(e.target.value);
                }}
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Button
                onClick={creatShipment}
                sx={{
                  width: "100%",
                  textTransform: "none",
                  background: "#1e5267",
                  ":hover": { background: "#1e5267" },
                }}
                variant="contained"
              >
                Create
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default ShipmentPage;
