import edjsParser from "editorjs-parser";
import React from "react";

import "../index.css";

const parser = new edjsParser({
  paragraph: {
    pClass: "parserparagraph",
  },
  embed: {
    useProvidedLength: true,
    width: "100%",
  },
  quote: {
    applyAlignment: false,
  },
});

export default function EditorTextParser({ data, expanded }) {
  let width = expanded ? "655px" : "270px";
  let height = expanded ? "370px" : "150px";
  let maxWidth = expanded ? "100%" : "100%";
  let fontSize = expanded ? "18px" : "16px";
  let imgBorder = expanded ? "15px" : "10px";
  let imgMT = expanded ? "25px" : "15px";
  let textColor = "#000";

  // array of HTML elements
  let markup = parser.parse(data);

  // Replace the width and height attributes in the iframe tag
  markup = markup.replace(/<iframe[^>]*>/, (match) => {
    // Replace the width and height attributes with variables
    const updatedIframe = match.replace(/width="[^"]*"/, `width="${width}"`);
    return updatedIframe.replace(/height="[^"]*"/, `height="${height}"`);
  });

  // Add margin: 0 style to <figure> elements
  markup = markup.replace(/<figure[^>]*>/g, (match) => {
    if (!match.includes("style")) {
      // Add style attribute for margin if it's missing
      return `<figure style="margin: 0;"${match}`;
    } else {
      return match;
    }
  });

  markup = markup.replace(/<img/g, (match) => {
    if (!match.includes("style")) {
      // Add style attribute for width if it's missing
      return `<img style="width: ${width}; border-radius:${imgBorder}; margin-top:${imgMT}"${match}`;
    } else {
      return match;
    }
  });
  markup = markup.replace(/<p/g, (match) => {
    if (!match.includes("style")) {
      // Add style attribute for width if it's missing
      return `<p style="max-width: ${maxWidth};font-size:${fontSize}; font-family: Sfregular; color:${textColor} " ${match}`;
    } else {
      return match;
    }
  });

  function createMarkup() {
    console.log(markup);
    return { __html: markup };
  }

  return (
    <div
      className="text-container-edjs"
      style={{
        width: "100%",
        fontFamily: "Sfregular",
        background: "white",
        display: "flex",
        flexDirection: "column",
        marginLeft: "auto",
        marginRight: "auto",
        overflowX: "hidden",
        wordBreak: "break-all",
      }}
      dangerouslySetInnerHTML={createMarkup()}
    />
  );
}
