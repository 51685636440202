export const xmiURL = "https://xmi.xnacloud.com/";
// export const xmiURL = "http://143.244.185.107:5555/";

export const loginURL = xmiURL + "login";

export const uploadSampleSheetURL = "https://apicirc.xnacloud.com/user/media";
// export const uploadSampleSheetURL = "http://143.244.185.107:8000/user/media";

export const uploadURL = xmiURL + "upload";

export const drxnaUrl = "https://apidrxna.xnacloud.com";
// export const drxnaUrl = "http://143.244.185.107:8005";

export const contentURL = "https://apicirc.xnacloud.com/";
export const topicURL = contentURL + "topic";
export const courseContentURL = contentURL + "content";
export const userContentURL = contentURL + "user";

export const getSamplesURL = xmiURL + "get_samples";

export const getASampleURL = xmiURL + "get_sample";

export const userURL = xmiURL + "get_user";

export const shipmentURL = drxnaUrl + "/shipment";
export const userMediaBaseURL = "https://apicirc.xnacloud.com/user/media";
