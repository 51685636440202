import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { getSampleByID } from "../services/drxna.service";
import { useEffect, useState } from "react";

import HttpService from "../services/http.service";
import { drxnaUrl } from "../services/url.service";
import SampleInspection from "../components/SampleInspection";

const SampleDetailPage = () => {
  const { sampleId, shipmentId } = useParams();
  const navigate = useNavigate();

  const [sampleDetail, getSampleDetail] = useState({});

  const [loading, setLoading] = useState(false);

  const getSample = () => {
    setLoading(true);
    getSampleByID(sampleId)
      .then((res) => {
        console.log(res);
        getSampleDetail(res?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [sampleInfo, setSampleInfo] = useState({});
  const getSampleInfo = () => {
    setLoading(true);
    HttpService.get(
      `${drxnaUrl}/shipment/${shipmentId}/sample/${sampleId}/inspection/outbound`
    )
      .then((res) => {
        setSampleInfo(res?.data?.data || {});
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSample();
    getSampleInfo();
  }, []);

  const [showConfirm, setShowConfirm] = useState(false);
  const confirmSample = () => {
    setLoading(true);
    HttpService.put(
      `${drxnaUrl}/shipment/${shipmentId}/sample/${sampleId}/inspection/outbound/confirm?final_status=OK`,
      {}
    )
      .then((res) => {
        if (res?.data?.data?.inspection_status) {
          setShowConfirm(true);
          setTimeout(() => {
            navigate(`/shipment/${shipmentId}`);
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box>
      {loading && <LinearProgress />}
      <Box sx={{ p: 5, display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "600",
              color: "#1e293b",
            }}
          >
            Sample Detail Page
          </Typography>

          {sampleInfo && (
            <Box>
              {showConfirm || sampleInfo?.inspection_status ? (
                <Box
                  sx={{
                    color: "#22c55e",
                    fontFamily: "Open Sans",
                    fontSize: "30px",
                  }}
                >
                  Sample Confirmed !
                </Box>
              ) : (
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    onClick={() => {
                      confirmSample();
                    }}
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      background: "#1e5267",
                      ":hover": { background: "#1e5267" },
                    }}
                  >
                    Confirm Sample
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            mb: 1,
            backgroundColor: "#fff",
            p: 2,
            cursor: "pointer",
            ":hover": {
              backgroundColor: "#ccd9ff",
            },
          }}
          onClick={() => {
            // handleFetchSamples(l.id);
          }}
        >
          <Box
            sx={{
              width: "20%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography sx={{ fontSize: "14px" }}> Name:</Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
              {sampleDetail?.["Sample Name"]}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "20%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography sx={{ fontSize: "14px" }}>Id:</Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
              {sampleDetail?.["Sample Id"]}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "20%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography sx={{ fontSize: "14px" }}>Description:</Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
              {" "}
              {sampleDetail?.Description}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "20%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography sx={{ fontSize: "14px" }}> Location:</Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
              {sampleDetail?.Location || "--"}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "20%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography sx={{ fontSize: "14px" }}> Status :</Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
              {sampleDetail?.status}
            </Typography>
          </Box>
        </Box>

        <SampleInspection sampleId={sampleId} shipmentId={shipmentId} />
      </Box>
    </Box>
  );
};

export default SampleDetailPage;
