import React from "react";
import { Box, Typography } from "@mui/material";

import "../index.css";

const DrxnaOrderCard = ({ name, desc, status, onClick }) => {
  return (
    <Box
      onClick={() => {
        onClick();
      }}
      sx={{
        background: "#fff",
        p: 4,
        width: "100%",
        borderRadius: "10px",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        gap: 0.5,
        boxShadow: " 0px 0px 31px 8px rgba(0,0,0,0.04)",
      }}
    >
      <Typography
        sx={{
          fontSize: "18px",
          fontFamily: "Sfmedium",
          fontWeight: "bold",
          color: "#334155",
        }}
      >
        {name}
      </Typography>
      <Typography sx={{ color: "#334155", fontFamily: "Sfregular" }}>
        {desc}
      </Typography>
      <Typography
        sx={{ fontSize: "14px", color: "#47bcc0", fontFamily: "Sfmedium" }}
      >
        <span style={{ color: "#334155" }}>Status:</span> {status}
      </Typography>
    </Box>
  );
};

export default DrxnaOrderCard;
