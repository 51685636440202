import Toastify from "toastify-js";

const handleSuccess = (info) => {
  Toastify({
    text: info,
    backgroundColor: "#27aae1",
    duration: 5000,
    close: true,
    gravity: "top",
    position: "right",
    stopOnFocus: true,
  }).showToast();
};

const handleError = (info, color) => {
  Toastify({
    text: info,
    style: {
      background: color
        ? color
        : "linear-gradient(108.68deg, #467EEB 0%, #37DE7A 129.44%)",
    },
    duration: 3000,
    close: true,
    gravity: "top",
    position: "right",
    stopOnFocus: true,
  }).showToast();
};

export { handleError, handleSuccess };
