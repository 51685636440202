import { useState } from "react";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { TextField } from "@mui/material";

function InputPassword({ placeholder, value = "", handleChange }) {
  //   const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      type={showPassword ? "text" : "password"}
      fullWidth
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      size={"medium"}
      sx={{
        "& .MuiOutlinedInput-root": {
          display: "flex",
          // alignItems: "end",
          FontFamily: "Sfregular",
          fontWeight: "500",
          borderRadius: "7px",
          fontSize: "13.5px",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#cbd5e1",
          border: "1px solid #cbd5e1",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#cbd5e1",
          border: "1px solid #cbd5e1",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "#ff6412",
            border: "1px solid #ff6412",
          },
        "& input::placeholder": {
          fontSize: "14px",
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default InputPassword;
