import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  LinearProgress,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getExcludedSamplesLot, getSamples } from "../services/drxna.service";
import TreeComponent from "../components/TreeComponent";
import CloseIcon from "@mui/icons-material/Close";
import HttpService from "../services/http.service";
import { drxnaUrl } from "../services/url.service";
import { useNavigate } from "react-router-dom";
import SearchInputComponent from "../components/SearchInputComponent";

const LotsPage = () => {
  const navigate = useNavigate();
  const [samplesExcludingDrxna, setSamplesExcludingDrxna] = useState([]);

  const [rawSamplesExcludingDrxna, setRawSamplesExcludingDrxna] = useState([]);
  const [lotSearchVal, setLotSearchVal] = useState("");
  const [expanded, setExpanded] = useState([]);
  const [selectedSamples, setSelectedSamples] = useState("");
  const [loading, setLoading] = useState(false);
  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const [selectedSampleIds, setSelectedSampleIds] = useState([]);

  function filterString(input) {
    const items = input.split(",");
    const filteredItems = items.filter(
      (item) => item && !item.startsWith("lot")
    );

    const objectArray = filteredItems.map((sample_id, index) => ({
      sample_id: sample_id,
      sample_name: null,
      inspection_status: null,
    }));

    let final = objectArray;

    return final;
  }
  const getSelectedSampleIds = (val) => {
    setSelectedSampleIds(filterString(val));
  };

  const handleCheckboxChange = (event, sample) => {
    if (event.target.checked) {
      setSelectedSampleIds((prev) => [
        ...prev,
        {
          sample_id: sample?.["Sample Id"],
          sample_name: sample?.["Sample Name"] || null,
          inspection_status: sample.inspection_status || null,
        },
      ]);
    } else {
      setSelectedSampleIds((prev) =>
        prev.filter((item) => item.sample_id !== sample?.["Sample Id"])
      );
    }
  };

  const getSamplesExcludingDrxna = () => {
    setLoading(true);
    let organizationID = localStorage.getItem("orgid");
    getExcludedSamplesLot(organizationID)
      .then((res) => {
        const allSamplesExcludingDrxna = res?.data?.lots?.filter(
          (alfw) => alfw.type !== "reagent" && alfw.n_sample > 0
        );
        setSamplesExcludingDrxna(allSamplesExcludingDrxna);
        setRawSamplesExcludingDrxna(allSamplesExcludingDrxna);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  const [samplesList, setSamplesList] = useState([]);
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const getAllSamples = (p) => {
    setLoading(true);
    getSamples(p)
      .then((res) => {
        console.log(res);
        setSamplesList(res?.data?.samples || []);
        setPage(res?.data?.pagination?.total_pages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getSamplesExcludingDrxna();
  }, []);
  useEffect(() => {
    getAllSamples(currentPage);
  }, [currentPage]);

  useEffect(() => {
    const commanSeparated =
      selectedSampleIds
        ?.filter((item) => !item._id)
        ?.map((item) => item.sample_id)
        .join(",") + ",";

    if (commanSeparated) {
      setSelectedSamples(commanSeparated);
    }
  }, [selectedSampleIds]);

  const removeSampleToShipment = (id) => {
    const filtered = selectedSampleIds.filter(
      (sample) => sample.sample_id !== id
    );
    setSelectedSampleIds(filtered);
  };

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [shipmentName, setShipmentName] = useState("");

  const AddSampleToShipment = (shipmentId) => {
    const sampleIds = selectedSampleIds?.map((item) => item?.sample_id);
    setLoading(true);
    HttpService.put(
      `${drxnaUrl}/shipment/${shipmentId}/sample/?ignore_failure=true`,
      { sample_id_list: sampleIds }
    )
      .then((res) => {
        if (res?.data?.data) {
          navigate(`/shipment/${shipmentId}`);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const creatShipment = () => {
    setLoading(true);
    HttpService.post(`${drxnaUrl}/shipment/`, {
      name: shipmentName,
    })
      .then((res) => {
        if (res?.data?.data?._id) {
          AddSampleToShipment(res?.data?.data?._id);
        }
        setOpenCreateModal(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const searchFilterLogic = (value) => {
    setExpanded(["drxnaSamples", "others"]);
    if (value === "") {
      setSamplesExcludingDrxna(rawSamplesExcludingDrxna);
    }

    setLotSearchVal(value);

    const excludingDrxnaFiltered = rawSamplesExcludingDrxna.filter((item) =>
      item.name.trim().toLowerCase().startsWith(value.toLowerCase())
    );
    if (value !== "") {
      setSamplesExcludingDrxna(excludingDrxnaFiltered);
    }
  };

  const [listView, setListView] = useState(true);

  const getShipmentStatus = (status, shipment) => {
    let validId =
      shipment !== "" && shipment !== null && shipment !== undefined;
    let shipment_status;
    if (status === "created" && validId) {
      shipment_status = "In proccess";
    } else if (status === "ready" && validId) {
      shipment_status = "Completed";
    } else {
      shipment_status = "None";
    }

    return shipment_status;
  };

  return (
    <Box>
      {loading && <LinearProgress />}
      <Box sx={{ p: 5 }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "600",
              color: "#1e293b",
            }}
          >
            Created Samples Page
          </Typography>
          {selectedSampleIds?.length !== 0 && (
            <Button
              sx={{
                textTransform: "none",
                background: "#1e5267",
                ":hover": { background: "#1e5267" },
              }}
              onClick={() => {
                setOpenCreateModal(true);
              }}
              variant="contained"
            >
              Create Shipment
            </Button>
          )}
        </Box>
        <Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            {selectedSampleIds?.map((sample, index) => (
              <Box
                variant="outlined"
                color={sample?.inspection_status ? "success" : "warning"}
                sx={{
                  fontFamily: "Open Sans",
                  pl: 2,
                  pr: 1,
                  display: "flex",
                  gap: 2,
                  height: "35px",
                  minWidth: "200px",
                  alignItems: "center",
                  cursor: "pointer",
                  borderRadius: "8px",
                  border: sample?.inspection_status
                    ? "1px solid #16a34a"
                    : "1px solid #eab308",
                  color: sample?.inspection_status ? "#16a34a" : "#eab308",
                }}
                key={index}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                    py: 2,
                    gap: 4,
                    width: "100%",
                  }}
                >
                  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <Typography>Name: {sample?.sample_name}</Typography>
                    <Typography>ID: {sample?.sample_id}</Typography>
                    <Typography sx={{ color: "#334155", fontSize: "12px" }}>
                      {" "}
                      {/* status:{" "}
                      {sample?.inspection_status
                        ? "Confirmed"
                        : "Not Confirmed"} */}
                    </Typography>
                  </Box>

                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      removeSampleToShipment(sample?.sample_id);
                    }}
                    sx={{
                      fontSize: "16px",
                      width: "25px",
                      height: "25px",
                      ":hover": { color: "red" },
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
          <Box sx={{ my: 3, display: "flex", justifyContent: "space-between" }}>
            <Typography>Select samples to add to shipment.</Typography>
            <Box>
              <Button
                variant={listView ? "contained" : "outlined"}
                onClick={() => {
                  setListView(true);
                }}
                sx={{
                  borderRadius: "0px",
                  textTransform: "none",
                  boxShadow: "none",
                }}
              >
                Samples view
              </Button>
              <Button
                variant={!listView ? "contained" : "outlined"}
                onClick={() => {
                  setListView(false);
                }}
                sx={{
                  borderRadius: "0px",
                  textTransform: "none",
                  boxShadow: "none",
                }}
              >
                Lots view
              </Button>
            </Box>
          </Box>
          {listView ? (
            <Box>
              <TableContainer
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  height: "62vh",
                  mb: 2,
                }}
              >
                <Table>
                  <TableHead
                    sx={{
                      borderBottom: "1px solid #e2e8f0",
                      position: "sticky",
                      top: 0,
                      left: 0,
                      background: "#fff",
                    }}
                  >
                    <TableRow>
                      <TableCell sx={{ borderRight: "1px solid #e2e8f0" }}>
                        <Typography
                          variant="body2"
                          fontWeight={"600"}
                          sx={{ cursor: "pointer" }}
                        >
                          Sample ID
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderRight: "1px solid #e2e8f0" }}>
                        <Typography
                          variant="body2"
                          fontWeight={"600"}
                          sx={{ cursor: "pointer" }}
                        >
                          Sample Name
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderRight: "1px solid #e2e8f0" }}>
                        <Typography
                          variant="body2"
                          fontWeight={"600"}
                          sx={{ cursor: "pointer" }}
                        >
                          Description
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ borderRight: "1px solid #e2e8f0" }}>
                        <Typography
                          variant="body2"
                          fontWeight={"600"}
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          Status
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderRight: "1px solid #e2e8f0" }}>
                        <Typography
                          variant="body2"
                          fontWeight={"600"}
                          sx={{ cursor: "pointer" }}
                        >
                          Shipment
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ overflowY: "auto" }}>
                    {samplesList &&
                      samplesList?.map((sample, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            backgroundColor:
                              index % 2 === 0 ? "#FFFFFF" : "#fff3eb", // even row color
                          }}
                        >
                          <TableCell
                            sx={{
                              borderRight: "1px solid #e2e8f0",
                              borderBottom: "none",
                              px: "16px",
                            }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Checkbox
                                value={sample?.["Sample Id"]}
                                checked={selectedSampleIds?.some(
                                  (item) =>
                                    item?.sample_id === sample?.["Sample Id"]
                                )}
                                onChange={(e) => {
                                  handleCheckboxChange(e, sample);
                                }}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                              <Typography>{sample?.["Sample Id"]}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "1px solid #e2e8f0",
                              borderBottom: "none",
                              py: 0,
                              px: "16px",
                            }}
                          >
                            <Box>
                              <Typography>{sample?.["Sample Name"]}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "1px solid #e2e8f0",
                              borderBottom: "none",
                              py: 0,
                              px: "16px",
                            }}
                          >
                            <Box>
                              <Typography>{sample?.["Description"]}</Typography>
                            </Box>
                          </TableCell>

                          <TableCell
                            sx={{
                              borderRight: "1px solid #e2e8f0",
                              borderBottom: "none",
                              py: 2.5,
                              px: "16px",
                            }}
                          >
                            <Box>
                              <Typography>{sample?.["status"]}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              borderRight: "1px solid #e2e8f0",
                              borderBottom: "none",
                              py: 2.5,
                              px: "16px",
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{
                                  color:
                                    getShipmentStatus(
                                      sample?.status,
                                      sample?.shipment_id
                                    ) === "In proccess"
                                      ? "#0ea5e9"
                                      : getShipmentStatus(
                                          sample?.status,
                                          sample?.shipment_id
                                        ) === "Completed"
                                      ? "#22c55e"
                                      : "black",
                                }}
                              >
                                {getShipmentStatus(
                                  sample?.status,
                                  sample?.shipment_id
                                )}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {page > 1 && (
                <Pagination
                  count={page}
                  page={currentPage}
                  onChange={(e, p) => {
                    setCurrentPage(p);
                  }}
                  variant="outlined"
                  shape="rounded"
                />
              )}
            </Box>
          ) : (
            samplesExcludingDrxna && (
              <Box>
                <SearchInputComponent
                  hasClear={lotSearchVal}
                  clearAction={() => {
                    searchFilterLogic("");
                  }}
                  iconStart={false}
                  bgkcolor={"#fff"}
                  value={lotSearchVal}
                  handleChange={(e) => {
                    searchFilterLogic(e.target.value);
                  }}
                  placeholder={"Search by Lot name"}
                />
                <Box
                  sx={{
                    width: "100%",
                    maxHeight: "60vh",
                    minHeight: "60vh",
                    overflowY: "auto",
                    mb: 2,
                    border: "1px solid #ccc",
                    background: "#fff",
                  }}
                >
                  <Box>
                    <TreeComponent
                      childs={samplesExcludingDrxna}
                      setSaved={false}
                      getSelectedSampleIds={getSelectedSampleIds}
                      selectedSamples={selectedSamples}
                      setSelectedSamples={(val) => {
                        setSelectedSamples(val);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )
          )}
        </Box>
        <Dialog
          fullWidth={true}
          sx={{ height: 450 }}
          maxWidth={"xs"}
          open={openCreateModal}
          onClose={() => {
            setOpenCreateModal(false);
          }}
        >
          <DialogTitle>
            <Typography>Add Shipment Name</Typography>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Input
                sx={{ width: "100%" }}
                onChange={(e) => {
                  setShipmentName(e.target.value);
                }}
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Button
                onClick={creatShipment}
                sx={{
                  width: "100%",
                  textTransform: "none",
                  background: "#1e5267",
                  ":hover": { background: "#1e5267" },
                }}
                variant="contained"
              >
                Create
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default LotsPage;
