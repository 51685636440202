import React from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

import CloseIcon from "@mui/icons-material/Close";

import { makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";

const useStyles = makeStyles(({ theme }) => ({
  root: {
    "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ccc",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ccc",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ccc",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ccc",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  noborderroot: {
    "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f2f2f2",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f2f2f2",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f2f2f2",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f2f2f2",
    },
    "& input::placeholder": {
      fontSize: "15px",
    },
  },
}));

function SearchInputComponent({
  placeholder,
  value = "",
  bgkcolor = "#F5F7FE",
  handleChange,
  iconStart = true,
  noborder = false,
  size = "small",
  hasClear = false,
  clearAction = () => {},
}) {
  const classes = useStyles();

  return (
    <TextField
      type={"text"}
      fullWidth
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      className={noborder ? classes.noborderroot : classes.root}
      size={size}
      style={{
        backgroundColor: bgkcolor,
        borderRadius: "10px",
      }}
      InputProps={{
        startAdornment: iconStart ? (
          <InputAdornment position="start">
            <IconButton aria-label="searchicon" edge="start">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ) : (
          <></>
        ),
        endAdornment: iconStart ? (
          <></>
        ) : (
          <InputAdornment position="end">
            {hasClear && (
              <IconButton
                onClick={clearAction}
                aria-label="closeIcon"
                edge="end"
              >
                <CloseIcon />
              </IconButton>
            )}

            {/* <IconButton aria-label="searchicon" edge="end">
              <SearchIcon />
            </IconButton> */}
          </InputAdornment>
        ),
      }}
    />
  );
}

SearchInputComponent.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  iconStart: PropTypes.bool,
};

export default SearchInputComponent;
