import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

import Header from "../../components/Header";
import LeftSidebar from "../../components/LeftSidebar";

import "../../index.css";
import BottomNav from "../../components/BottomVav";
const Rootlayout = () => {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const getSidebarState = (val) => {
    setIsSidebarExpanded(val);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        background: "#fbfbfb",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          background: "#fff",
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 999,
        }}
      >
        <Header />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          position: "relative",
          mt: "70px",
          height: "calc(100vh - 70px)",
          width: "100vw",
        }}
      >
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <LeftSidebar onToggle={getSidebarState} />
        </Box>
        <Box
          sx={{
            height: "calc(100vh - 70px)",
            position: "relative",
            overflowY: "auto",
            width: {
              xs: "100vw",
              md: isSidebarExpanded
                ? "calc(100vw - 200px)"
                : "calc(100vw - 60px)",
            },
            transition: { xs: "width 0s", md: "width .2s" },
          }}
        >
          <Outlet />

          <BottomNav />
        </Box>
      </Box>
    </Box>
  );
};

export default Rootlayout;
