export function base64ToBlob(base64, contentType = "", sliceSize = 512) {
  const data = base64.split(",")[1];

  const binaryString = window.atob(data);
  const arrayBuffer = new ArrayBuffer(binaryString.length);
  const byteArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binaryString.length; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([arrayBuffer], { type: contentType });

  return blob;
}
