import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  Input,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import HttpService from "../services/http.service";
import { getExcludedSamplesLot } from "../services/drxna.service";

import TreeComponent from "../components/TreeComponent";
import { drxnaUrl } from "../services/url.service";
import CloseIcon from "@mui/icons-material/Close";
import SearchInputComponent from "../components/SearchInputComponent";
import PackageInspection from "../components/PackageInspection";

const ShipmentDetail = () => {
  const [shipmentDetail, setShipmentDetail] = useState({});
  const [outboundDetail, setOutBoundDetail] = useState({});
  const { shipmentId } = useParams();

  const navigate = useNavigate();

  const getInboundData = () => {
    HttpService.get(
      `${drxnaUrl}/shipment/${shipmentId}/inspection/outbound`
    ).then((res) => {
      setOutBoundDetail(res?.data?.data);
      setSelectedSampleIds(res?.data?.data?.sample_list || []);
    });
  };

  useEffect(() => {
    HttpService.get(`${drxnaUrl}/shipment/${shipmentId}`)
      .then((res) => {
        setShipmentDetail(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getInboundData();
  }, []);

  const [loading, setLoading] = useState(false);

  const [samplesExcludingDrxna, setSamplesExcludingDrxna] = useState([]);
  const [rawSamplesExcludingDrxna, setRawSamplesExcludingDrxna] = useState([]);
  const [lotSearchVal, setLotSearchVal] = useState("");

  const getSamplesExcludingDrxna = () => {
    setLoading(true);
    let organizationID = localStorage.getItem("orgid");
    getExcludedSamplesLot(organizationID)
      .then((res) => {
        const allSamplesExcludingDrxna = res?.data?.lots?.filter(
          (alfw) => alfw.type !== "reagent" && alfw.n_sample > 0
        );
        setSamplesExcludingDrxna(allSamplesExcludingDrxna);
        setRawSamplesExcludingDrxna(allSamplesExcludingDrxna);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getSamplesExcludingDrxna();
  }, []);

  const [selectedSampleIds, setSelectedSampleIds] = useState([]);

  const [selectedSamples, setSelectedSamples] = useState("");

  useEffect(() => {
    const commanSeparated =
      selectedSampleIds
        ?.filter((item) => !item._id)
        ?.map((item) => item.sample_id)
        .join(",") + ",";

    if (commanSeparated) {
      setSelectedSamples(commanSeparated);
    }
  }, [selectedSampleIds]);

  function filterString(ids, nameDatas) {
    const items = ids.split(",");
    const filteredItems = items.filter(
      (item) => item && !item.startsWith("lot")
    );

    const objectArray = filteredItems.map((sample_id, index) => {
      const name =
        nameDatas[index]?.id === sample_id ? nameDatas[index]?.name : "";
      return {
        sample_id: sample_id,
        sample_name: name,
        inspection_status: null,
      };
    });

    let final;
    if (outboundDetail?.sample_list) {
      final = [...outboundDetail?.sample_list, ...objectArray];
    } else {
      final = objectArray;
    }

    return final;
  }

  const getSelectedSampleIds = (id, nameDatas) => {
    setSelectedSampleIds(filterString(id, nameDatas));
  };

  const [sampleState, setSampleState] = useState({
    status: false,
    message: "",
  });

  const AddSampleToShipment = () => {
    const sampleIds = selectedSampleIds?.map((item) => item?.sample_id);
    setLoading(true);
    HttpService.put(
      `${drxnaUrl}/shipment/${shipmentId}/sample/?ignore_failure=true`,
      { sample_id_list: sampleIds }
    )
      .then((res) => {
        if (res?.data?.data) {
          setSampleState({ status: true, message: "Sample Added to shipment" });
        } else {
          setSampleState({
            status: false,
            message: "Sample is already in another shipment",
          });
        }

        setTimeout(() => {
          setSampleState({ status: false, message: "" });
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeSampleToShipment = (id, isSaved = false) => {
    if (isSaved) {
      setLoading(true);
      HttpService.remove(`${drxnaUrl}/shipment/${shipmentId}/sample/${id}`)
        .then((res) => {
          if (!res?.data?.error) {
            const filtered = selectedSampleIds.filter(
              (sample) => sample.sample_id !== id
            );
            setSelectedSampleIds(filtered);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const filtered = selectedSampleIds.filter(
        (sample) => sample.sample_id !== id
      );
      setSelectedSampleIds(filtered);
    }
  };

  const [showConfirm, setShowConfirm] = useState(false);
  const confirmShipment = () => {
    HttpService.put(
      `${drxnaUrl}/shipment/${shipmentId}/inspection/outbound/confirm?final_status=OK`
    )
      .then((res) => {
        if (res?.data?.data?.inspection_status) {
          setShowConfirm(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [trackingId, setTrackingId] = useState("");

  const addTrackingId = () => {
    setLoading(true);
    HttpService.put(`${drxnaUrl}/shipment/${shipmentId}`, {
      tracking_id: trackingId,
    })
      .then((res) => {
        setShipmentDetail((prev) => {
          return {
            ...prev,
            tracking_id: res?.data?.data?.tracking_id,
            shipment_status: res?.data?.data?.shipment_status,
          };
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setTrackingId("");
      });
  };

  const searchFilterLogic = (value) => {
    if (value === "") {
      setSamplesExcludingDrxna(rawSamplesExcludingDrxna);
    }

    setLotSearchVal(value);

    const excludingDrxnaFiltered = rawSamplesExcludingDrxna.filter((item) =>
      item.name.trim().toLowerCase().startsWith(value.toLowerCase())
    );
    if (value !== "") {
      setSamplesExcludingDrxna(excludingDrxnaFiltered);
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      {loading && <LinearProgress />}
      <Box sx={{ p: 5 }}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "600",
              color: "#1e293b",
            }}
          >
            Shipment Detail
          </Typography>

          {shipmentDetail?.shipment_status !== "delivered" && (
            <Box sx={{ display: "flex", gap: 2 }}>
              {showConfirm ||
              shipmentDetail?.outbound_data?.inspection_status ? (
                <Box sx={{ display: "flex", gap: 3 }}>
                  <Box>
                    <Button
                      onClick={addTrackingId}
                      variant="contained"
                      size="sm"
                      sx={{
                        textTransform: "none",
                        mr: 1,
                        background: "#1e5267",
                        ":hover": { background: "#1e5267" },
                      }}
                    >
                      {shipmentDetail?.tracking_id ? "Edit" : "Add"}
                    </Button>
                    <Input
                      value={trackingId}
                      onChange={(e) => {
                        setTrackingId(e.target.value);
                      }}
                      variant="contained"
                      placeholder="Tracking Id"
                    />
                  </Box>
                  <Box
                    sx={{
                      color: "#22c55e",
                      fontFamily: "Open Sans",
                      fontSize: "30px",
                    }}
                  >
                    Shipment Confirmed !
                  </Box>
                </Box>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      confirmShipment();
                    }}
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      background: "#1e5267",
                      ":hover": { background: "#1e5267" },
                    }}
                  >
                    Confirm Shipment
                  </Button>
                </>
              )}
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            mb: 1,
            backgroundColor: "#fff",
            p: 2,
            cursor: "pointer",
            ":hover": {
              backgroundColor: "#ccd9ff",
            },
          }}
        >
          <Box
            sx={{
              width: "25%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography sx={{ fontSize: "14px" }}> Name:</Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
              {shipmentDetail?.name}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "25%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography sx={{ fontSize: "14px" }}>Id:</Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
              {shipmentDetail?._id}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "25%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography sx={{ fontSize: "14px" }}>Shipment Status:</Typography>
            <Typography
              sx={{
                color:
                  shipmentDetail?.shipment_status === "cancelled"
                    ? "red"
                    : shipmentDetail?.shipment_status === "delivered"
                    ? "#22c55e"
                    : shipmentDetail?.shipment_status === "enroute"
                    ? "#0ea5e9"
                    : "#000",
                fontSize: "16px",
                fontWeight: "600",
              }}
            >
              {shipmentDetail?.shipment_status}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "25%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography sx={{ fontSize: "14px" }}> Tracking Id:</Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
              {shipmentDetail?.tracking_id || "--"}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            zIndex: 999,
            position: "relative",
          }}
        >
          <Typography sx={{ mb: 2 }}>
            {shipmentDetail?.shipment_status === "created"
              ? "Select Samples to Add Shipment"
              : "Selected Samples"}{" "}
          </Typography>
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
            {selectedSampleIds?.map((sample, index) => (
              <Box
                onClick={() => {
                  navigate(`/sample/${sample?.sample_id}/${shipmentId}`);
                }}
                variant="outlined"
                color={sample?.inspection_status ? "success" : "warning"}
                sx={{
                  fontFamily: "Open Sans",
                  pl: 2,
                  pr: 1,
                  display: "flex",
                  gap: 2,
                  height: "35px",
                  minWidth: "200px",
                  alignItems: "center",
                  cursor: "pointer",
                  borderRadius: "8px",
                  border: sample?.inspection_status
                    ? "1px solid #16a34a"
                    : "1px solid #eab308",
                  // color: sample?.inspection_status ? "#16a34a" : "#eab308",
                }}
                key={index}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                    py: 2,
                    gap: 4,
                    width: "100%",
                  }}
                >
                  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <Typography>Name: {sample?.sample_name}</Typography>
                    <Typography>ID: {sample?.sample_id}</Typography>
                    <Typography sx={{ color: "#334155", fontSize: "12px" }}>
                      {" "}
                      status:{" "}
                      {sample?.inspection_status
                        ? "Confirmed"
                        : "Not Confirmed"}
                    </Typography>
                  </Box>
                  {shipmentDetail?.shipment_status === "created" && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        let isSaved = sample?._id ? true : false;
                        removeSampleToShipment(sample?.sample_id, isSaved);
                      }}
                      sx={{
                        fontSize: "16px",
                        width: "25px",
                        height: "25px",
                        ":hover": { color: "red" },
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
          <Box sx={{ my: 2 }}>
            {sampleState?.message !== "" && (
              <Typography
                sx={{
                  color: sampleState?.status ? "#16a34a" : "red",
                  fontSize: "13px",
                }}
              >
                {sampleState?.message}
              </Typography>
            )}
          </Box>

          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {samplesExcludingDrxna &&
              shipmentDetail?.shipment_status === "created" && (
                <Box sx={{ width: "100%" }}>
                  <SearchInputComponent
                    hasClear={lotSearchVal}
                    clearAction={() => {
                      searchFilterLogic("");
                    }}
                    iconStart={false}
                    bgkcolor={"#fff"}
                    value={lotSearchVal}
                    handleChange={(e) => {
                      searchFilterLogic(e.target.value);
                    }}
                    placeholder={"Search by Lot name"}
                  />
                  <Box
                    sx={{
                      width: "100%",
                      maxHeight: "30vh",
                      minHeight: "30vh",
                      overflowY: "auto",
                      mb: 2,
                      border: "1px solid #ccc",
                      background: "#fff",
                    }}
                  >
                    <Box>
                      <TreeComponent
                        childs={samplesExcludingDrxna}
                        setSaved={false}
                        getSelectedSampleIds={getSelectedSampleIds}
                        selectedSamples={selectedSamples}
                        setSelectedSamples={(val) => {
                          setSelectedSamples(val);
                        }}
                        showFiltered={true}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
          </Box>
          {shipmentDetail?.shipment_status === "created" && (
            <Button
              onClick={() => {
                AddSampleToShipment();
              }}
              variant="contained"
              sx={{
                textTransform: "none",
                background: "#1e5267",
                ":hover": { background: "#1e5267" },
              }}
            >
              Add Sample
            </Button>
          )}
        </Box>
        <PackageInspection id={shipmentId} />
      </Box>
    </Box>
  );
};

export default ShipmentDetail;
