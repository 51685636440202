import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";

import DrxnaOrderCard from "../../components/DrxnaOrderCard";
import { WebcamCapture } from "../../components/WebcamComponent";

import FileInput from "../../components/FileInput";
import { uploadFile } from "../../services/drxna.service";

import { ordersListGeter } from "../../services/drxna.service";
import { actionGeter } from "../../services/drxna.service";
import { actionSubmitter } from "../../services/drxna.service";
import EditorTextParser from "../../components/ContentParser";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "../../index.css";
import ProgressTimeline from "../../components/ProgressTimeline";

const DrxnaSamples = () => {
  const [showActionsSection, setShowActionsSection] = useState(false);
  const [ordersList, setOrderList] = useState([]);
  const [isGettingOrdersList, setIsGettingOrdersList] = useState(false);

  const [updatedOrder, setUpdatedOrder] = useState(null);

  // get all orders
  const getOrderList = () => {
    setIsGettingOrdersList(true);
    ordersListGeter()
      .then((res) => {
        setOrderList(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsGettingOrdersList(false);
      });
  };

  // open actions
  const openActions = (orderId) => {
    setShowActionsSection(true);
    getActions(orderId);
  };

  const [orderId, setOrderId] = useState("");
  const [form, setForm] = useState([]);

  const [formData, setFormData] = useState([]);
  const [wfDetail, setWfDetail] = useState(null);

  const [buttonName, setButtonName] = useState("");
  const [statusData, setStatusData] = useState(null);
  const [actionToBeSubmitted, setActionToBeSubmited] = useState(null);

  console.log(actionToBeSubmitted, "ff");
  const [statusStage, setStatusStage] = useState(false);

  const [formIdentifier, setFormIdentifier] = useState("");

  // get actions
  const getActions = (orderid) => {
    setFormIdentifier("");
    actionGeter(orderid)
      .then((res) => {
        console.log(res);
        setActionToBeSubmited(null);
        setForm([]);
        setFormData([]);
        setStatusStage(false);

        const nextObject = res.data.find((obj) => obj.action === "next");
        const statusObject = res.data.find((obj) => obj.action === "status");

        if (nextObject) {
          setActionToBeSubmited(nextObject);
          const label = nextObject.label || "No label";
          setButtonName(label);
          const content = nextObject.data.content || "No content";

          if (content.blocks) {
            content.blocks.forEach((block) => {
              if (block.type === "form") {
                setFormIdentifier(block.id);
                const convertedArray = content.blocks[0].data.content?.map(
                  (item) => ({
                    [item[0]]: item[1],
                  })
                );
                setForm(convertedArray);
                setFormData(convertedArray);
              } else {
                setWfDetail(content);
              }
            });
          }
        } else if (statusObject) {
          setStatusStage(true);
          setStatusData(statusObject);
          setButtonName(statusObject.label);
        } else {
          console.log("No object with action 'next' or 'status' found");
        }
      })
      .catch((err) => console.log(err));
  };

  // form submition logic
  const [orderFormVals, setOrderFormVals] = useState({});

  const handleOrderFormSubmition = (key, value) => {
    setOrderFormVals((prevValues) => ({ ...prevValues, [key]: value }));
  };

  const [isSendingFormData, setIsSendingFormData] = useState(false);

  // function to submit sample validation form data
  const handleSampleValidationSubmit = () => {
    setIsSendingFormData(true);
    actionSubmitter(orderId, statusStage ? statusData : actionToBeSubmitted)
      .then((res) => {
        getActions(orderId);
        setOrderFormVals({});
        if (statusData) {
          setWfDetail(res.data.content);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsSendingFormData(false);
      });
  };

  const [isPhotoUploaded, setIsPhotoUploaded] = useState(false);

  // image uploading

  // structure img data to be sent
  const structureImgData = (val) => {
    const newArray = formData?.map((obj) => {
      if (obj.hasOwnProperty("media_url")) {
        return { media_url: val };
      } else {
        return obj;
      }
    });
    setFormData(newArray);
  };

  const handleFormValueChange = (index, value) => {
    setIsPhotoUploaded(true);
    structureImgData([value]);
  };
  const handleUploadSampleImg = (index, datas) => {
    const imgsArray = [];

    for (let i = 0; datas.length > 5 ? i < 5 : i < datas.length; i++) {
      const file = datas[i];

      console.log("file of uploaded img", file);
      uploadFile(file)
        .then((res) => {
          imgsArray.push(
            `https://apicirc.xnacloud.com/media/${res.data.data[0][0].id}`
          );

          console.log("array", imgsArray);
          structureImgData(imgsArray);
          setIsPhotoUploaded(true);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  // function to submit forms data other than sample validation form
  const handleSubmit = () => {
    const revertedArray = formData?.map((obj) => Object.entries(obj)[0]);
    const updatedOrderState = statusStage
      ? null
      : {
          ...actionToBeSubmitted,
          data: {
            ...actionToBeSubmitted.data,
            content: {
              ...actionToBeSubmitted.data.content,
              blocks: [
                {
                  ...actionToBeSubmitted.data.content.blocks[0],
                  data: {
                    ...actionToBeSubmitted.data.content.blocks[0].data,
                    content: revertedArray,
                  },
                },
              ],
            },
          },
        };

    setIsSendingFormData(true);
    setUpdatedOrder(updatedOrderState);
    actionSubmitter(orderId, statusStage ? statusData : updatedOrderState)
      .then((res) => {
        getActions(orderId);
        setOrderFormVals({});
        if (statusData) {
          setWfDetail(res.data.content);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsSendingFormData(false);
      });
  };

  function capitalizeString(inputString) {
    if (typeof inputString !== "string") {
      return "Invalid input";
    }
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }

  useEffect(() => {
    getOrderList();
  }, []);

  const [dropdownVal, setdropdownVal] = useState("");

  const [existingSamplesList, setExistingSamplesList] = useState([]);
  const [log, setLog] = useState([]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        overflowY: "auto",
      }}
    >
      {!showActionsSection && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            position: "relative",
            width: "100%",
          }}
        >
          <Grid
            container
            sx={{
              width: "100%",
              p: 2,
            }}
          >
            {isGettingOrdersList && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "calc(100vh - 70px)",
                  background: "#00000005",
                }}
              >
                <CircularProgress sx={{ m: "auto", color: "#1e5267" }} />
              </Box>
            )}
            {ordersList &&
              ordersList?.map((order, index) => (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={6}
                  md={4}
                  xl={3}
                  sx={{ display: "flex", px: 1, pb: 2 }}
                >
                  {" "}
                  <DrxnaOrderCard
                    onClick={() => {
                      setLog(order?.log);
                      setExistingSamplesList(order?.existing_sample_id_list);
                      openActions(order?.order_id);
                      setOrderId(order?.order_id);
                    }}
                    name={order?.name ? order?.name : "..."}
                    desc={order?.description ? order?.description : "..."}
                    status={order?.progress}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
      )}
      {showActionsSection && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            overflowX: "hidden",
            flexGrow: 1,
            p: 4,
          }}
        >
          <Box>
            <Button
              startIcon={<ArrowBackIcon sx={{ color: "#1e5267" }} />}
              onClick={() => {
                setShowActionsSection(false);
                setForm([]);
                setButtonName("");
                setStatusStage(false);
                setIsPhotoUploaded(false);
              }}
              sx={{
                textTransform: "none",
                fontSize: "16px",
                color: "#1e5267",
                fontFamily: "Sfregular",
              }}
            >
              Back
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
            }}
          >
            {form &&
              form?.map(
                (item, index) =>
                  index !== 0 && (
                    <Box key={index} sx={{ width: "100%" }}>
                      {Object.entries(item)?.map(
                        ([key, value]) =>
                          // not a good way of excluding the '_id' from the maping
                          key !== "_id" && (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: 1,
                                width: "100%",
                              }}
                            >
                              {key !== "media_url" ? (
                                <Box
                                  sx={{
                                    width: "100%",
                                    maxWidth: "500px",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 1,
                                  }}
                                >
                                  <Typography
                                    key={index}
                                    sx={{
                                      color: "#475569",
                                      fontSize: "16px",
                                      fontFamily: "Sfregular",
                                    }}
                                  >
                                    {capitalizeString(key)}
                                  </Typography>

                                  {actionToBeSubmitted?.data?.content?.blocks[0]
                                    ?.id === "execution_form" ? (
                                    <Select
                                      value={dropdownVal || "False"}
                                      onChange={(e) => {
                                        const objIndex = formData.findIndex(
                                          (obj) => Object.keys(obj)[0] === key
                                        );
                                        console.log(e.target.value);
                                        if (objIndex !== -1) {
                                          setFormData((prev) => [
                                            ...prev.slice(0, objIndex),
                                            { [key]: e.target.value },
                                            ...prev.slice(objIndex + 1),
                                          ]);
                                        }
                                        handleOrderFormSubmition(
                                          key,
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <MenuItem
                                        value={"False"}
                                        onClick={() => {
                                          setdropdownVal(value);
                                        }}
                                      >
                                        {value}
                                      </MenuItem>
                                      <MenuItem
                                        value={"True"}
                                        onClick={() => {
                                          setdropdownVal("True");
                                        }}
                                      >
                                        True
                                      </MenuItem>
                                    </Select>
                                  ) : (
                                    <TextField
                                      // disabled={formIdentifier === "sample_validation_form"}
                                      key={key}
                                      variant="outlined"
                                      size="medium"
                                      sx={{
                                        width: "100%",
                                        maxWidth: "500px",
                                        "& .MuiOutlinedInput-root": {
                                          display: "flex",
                                          alignItems: "end",
                                          FontFamily: "Sfregular",
                                          fontWeight: "500",
                                          borderRadius: "7px",
                                          fontSize: "13.5px",
                                        },
                                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                          {
                                            borderColor: "#cbd5e1",
                                            border: "1px solid #cbd5e1",
                                          },
                                        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                          {
                                            borderColor: "#cbd5e1",
                                            border: "1px solid #cbd5e1",
                                          },
                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                          {
                                            borderColor: "#1e5267",
                                            border: "1px solid #1e5267",
                                          },
                                        "& input::placeholder": {
                                          fontSize: "12px",
                                        },
                                      }}
                                      onChange={(e) => {
                                        const objIndex = formData.findIndex(
                                          (obj) => Object.keys(obj)[0] === key
                                        );
                                        if (objIndex !== -1) {
                                          setFormData((prev) => [
                                            ...prev.slice(0, objIndex),
                                            { [key]: e.target.value },
                                            ...prev.slice(objIndex + 1),
                                          ]);
                                        }
                                        handleOrderFormSubmition(
                                          key,
                                          e.target.value
                                        );
                                      }}
                                      defaultValue={
                                        formIdentifier === "sample_photo_form"
                                          ? ""
                                          : value
                                      }
                                    />
                                  )}
                                </Box>
                              ) : (
                                <>
                                  <WebcamCapture
                                    imagec=""
                                    name="Sample"
                                    handleCaptureImage={(e) => {
                                      handleFormValueChange("", e);
                                    }}
                                  />
                                  <FileInput
                                    bgColor="#f2f2f2"
                                    textColor="#000"
                                    uploadlabel="Upload From File"
                                    handleChange={(e) => {
                                      handleUploadSampleImg("", e.target.files);
                                    }}
                                  />
                                </>
                              )}
                            </Box>
                          )
                      )}
                    </Box>
                  )
              )}

            <Button
              onClick={
                formIdentifier === ""
                  ? handleSampleValidationSubmit
                  : handleSubmit
              }
              disabled={
                formIdentifier === "sample_photo_form"
                  ? !isPhotoUploaded
                  : isSendingFormData
              }
              variant="contained"
              sx={{
                width: "100%",
                maxWidth: "500px",
                overflow: "hidden",
                height: "52px",
                color: "white",
                textTransform: "none",
                boxShadow: 0,
                position: "relative",
                background: "#1e5267",
                "&:hover": {
                  background: "#1e5267",
                  boxShadow: 0,
                },
                fontSize: "18px",
                fontFamily: "Sfregular",
              }}
            >
              {isSendingFormData && (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#ffffffbd",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                >
                  <CircularProgress size={30} sx={{ color: "#1e5267" }} />
                </Box>
              )}
              {buttonName}
            </Button>
          </Box>

          {wfDetail &&
            statusStage &&
            (wfDetail?.unformatted_data?.progress_list_status ? (
              <Box sx={{ width: "100%", py: "100px" }}>
                <ProgressTimeline
                  orderid={orderId}
                  data={wfDetail?.unformatted_data?.progress_list_status}
                  payload={statusStage ? statusData : updatedOrder}
                  datalog={log}
                  psample={existingSamplesList}
                />{" "}
              </Box>
            ) : (
              <Box
                sx={{
                  overflowY: "auto",
                  flexGrow: 1,
                  height: "60vh",
                  mt: 2,
                }}
              >
                <EditorTextParser data={wfDetail} expanded={true} />
              </Box>
            ))}
        </Box>
      )}
    </Box>
  );
};

export default DrxnaSamples;
