import axios from "axios";
// import { handleError, handleSuccess } from "../utils/toasts";

const http = axios.create();

http.interceptors.request.use((config) => {
  const token = localStorage.getItem("usertoken") || "";
  if (token) {
    config.headers.Authorization = "Bearer " + token;
  }
  return config;
});

http.interceptors.response.use(
  (result) => {
    // if (result.status === 201) {
    // }
    return result;
  },
  (error) => {
    if (error.response) {
      if (
        window.location.pathname !== "/login" &&
        error.response.status === 401
      ) {
        localStorage.clear();
        window.location.href = "/login";
      }
    } else {
      console.log("error response");
    }
  }
);

const post = (url, data, params = {}) => {
  const header = {
    Authorization: "Bearer " + localStorage.getItem("usertoken") || "",
  };

  return http.post(url, data, {
    ...params,
    headers: header,
  });
};

const multi_part_put = (url, data, params = {}) => {
  const header = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + localStorage.getItem("usertoken") || "",
  };

  return axios.put(url, data, { ...params, headers: header });
};

const multi_part_post = (url, data, params = {}) => {
  const header = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + localStorage.getItem("usertoken") || "",
  };

  return axios.post(url, data, { ...params, headers: header });
};

const get = (url, queries, params = {}) => {
  const header = {
    Authorization: "Bearer " + localStorage.getItem("usertoken") || "",
  };
  return http.get(url, {
    params: queries,
    ...params,
    headers: header,
  });
};

const put = (url, data, params = {}) => {
  const header = {
    Authorization: "Bearer " + localStorage.getItem("usertoken") || "",
  };
  return http.put(url, data, {
    ...params,
    headers: header,
  });
};

const remove = (url, data) => {
  const header = {
    Authorization: "Bearer " + localStorage.getItem("usertoken") || "",
  };
  return http.delete(url, {
    headers: header,
    data,
  });
};

const HttpService = {
  http,
  get,
  post,
  multi_part_post,
  put,
  multi_part_put,
  remove,
};

export default HttpService;
