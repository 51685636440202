import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Grid,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { loginUser } from "../../services/auth.service";
import InputPassword from "../../components/InputPassword";

import logo from "../../assets/logotwo.png";

import "../../index.css";
import { getUser } from "../../services/drxna.service";

const LoginPage = () => {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [showErrorMsg, setShowErrorMsg] = useState(false);

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const getUsersData = async (response) => {
    const getuserreq = await getUser(response.data.user_id);
    localStorage.setItem("username", getuserreq?.data?.email);
    localStorage.setItem("orgname", getuserreq?.data?.organization);
    localStorage.setItem("orgid", getuserreq.data.organizations[0]);
  };

  const handleLogin = () => {
    if (username !== "" && password !== "") {
      setIsLoggingIn(true);
      loginUser({ email: username, password: password })
        .then((res) => {
          if (res?.data?.jwt) {
            let token = res?.data?.jwt;
            let userid = res?.data?.user_id;
            localStorage.setItem("usertoken", token);
            localStorage.setItem("user", username);
            localStorage.setItem("userid", userid);
            getUsersData(res);
            navigate("/samples");
          } else {
            ShowErrorToaster();
          }
        })
        .catch((e) => {
          console.log(e);
          ShowErrorToaster();
        })
        .finally(() => {
          setIsLoggingIn(false);
        });
    } else {
      setShowErrorMsg(true);
      setErrorMsg("please fill all the required fields!");
    }
  };

  const [isErrorToasterVisible, setIsErrorToasterVisible] = useState(false);
  const ShowErrorToaster = () => {
    setIsErrorToasterVisible(true);
    setTimeout(() => {
      setIsErrorToasterVisible(false);
    }, 1500);
  };

  return (
    <Grid
      container
      sx={{
        display: "flex",
        width: "100%",
        height: "100vh",
        background:
          "linear-gradient(90deg, #FF3D00 -6.4%, #FF8120 36.37%, #32C3D3 100%)",
      }}
    >
      {/* <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: {
            xs: "none",
            md: "flex",
            background: "#00000021",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="" style={{ width: "100px" }} />
          <Typography
            sx={{
              fontFamily: "Sfbold",
              fontSize: "50px",
              color: "#fff",
            }}
          >
            Dr. XNA
          </Typography>
        </Box>
      </Grid> */}
      {isErrorToasterVisible && (
        <Box
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            background: "red",
            px: 4,
            py: 2,
            borderRadius: "5px",
            transition: "all .3s",
          }}
        >
          <Typography
            sx={{ color: "white", fontFamily: "Sfregular", fontSize: "16px" }}
          >
            Invalid Credentials
          </Typography>
        </Box>
      )}
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "80%",
            maxWidth: "450px",
            gap: 3,
            background: "white",
            px: 3,
            pb: "20px",
            borderRadius: "10px",
            boxShadow: " 0px 0px 31px 8px rgba(0,0,0,0.04)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "96%",
              background: "#f8fafc",
              mt: "30px",
              mb: "20px",
              py: 2,
              borderRadius: "7px",
              px: 1,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Sfregular",
                fontWeight: "700",
                fontSize: "20px",
                color: "#475569",
                letterSpacing: 1,
              }}
            >
              Login
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
              <img src={logo} alt="" style={{ width: "40px" }} />
              <Typography
                sx={{
                  fontFamily: "Sfbold",
                  fontSize: "20px",
                  color: "#475569",
                }}
              >
                Dr.XNA
              </Typography>
            </Box>
          </Box>
          <TextField
            size={"medium"}
            placeholder="Enter your username"
            onChange={(e) => {
              setUsername(e.target.value);
              if (showErrorMsg) {
                setShowErrorMsg(false);
              }
            }}
            value={username}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                display: "flex",
                alignItems: "end",
                FontFamily: "Sfregular",
                fontWeight: "500",
                borderRadius: "7px",
                fontSize: "13.5px",
              },
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "#cbd5e1",
                border: "1px solid #cbd5e1",
              },
              "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#cbd5e1",
                  border: "1px solid #cbd5e1",
                },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#ff6412",
                  border: "1px solid #ff6412",
                },
              "& input::placeholder": {
                fontSize: "14px",
              },
            }}
          />
          <InputPassword
            placeholder="Enter your password"
            handleChange={(e) => {
              setPassword(e.target.value);
              if (showErrorMsg) {
                setShowErrorMsg(false);
              }
            }}
            value={password}
          />
          <Button
            variant="contained"
            onClick={handleLogin}
            sx={{
              mt: 4,
              width: "100%",
              maxWidth: "500px",
              overflow: "hidden",
              height: "52px",
              color: "white",
              textTransform: "none",
              fontSize: "16px",
              boxShadow: 0,
              position: "relative",
              background: "#ff6412",
              "&:hover": {
                background: "#ff6412",
                boxShadow: 0,
              },
            }}
          >
            {isLoggingIn && (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#ffffffbd",
                  position: "absolute",
                  fontFamily: "Sfregular",
                  fontSize: "16px",
                  top: 0,
                  left: 0,
                }}
              >
                <CircularProgress size={30} sx={{ color: "#ff6412" }} />
              </Box>
            )}
            Login
          </Button>
          <Box sx={{ height: "10px", mt: "-10px" }}>
            <Typography
              sx={{ fontFamily: "Sfregular", fontSize: "12px", color: "red" }}
            >
              {showErrorMsg ? errorMsg : ""}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
