import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getShipmentInspection,
  updatePackageInspection,
  uploadFile,
} from "../services/instruments.service";
import { base64ToBlob } from "../utils/image";
import TextInputComponent from "./TextInput";
import { WebcamCapture } from "./WebcamComponent";
import FileInput from "./FileInput";
import { handleSuccess } from "../utils/toast";
import PdfViewer from "./PdfViewer";
import axios from "axios";

const PackageInspection = ({ id }) => {
  const uid = localStorage.getItem("userid");
  const token = localStorage.getItem("usertoken");
  const [loading, setLoading] = useState(false);

  const [capture, setCapture] = useState("");

  const [inspectionData, setInspectionData] = useState({
    capture: "",
    description: "",
    file_list: [],
    image_list: [],
    video_list: [],
  });

  const handleCaptureImage = (b64img) => {
    setLoading(true);
    setCapture(b64img);
    const blob = base64ToBlob(b64img, "image/jpeg");
    const file = new File([blob], "pacakge_capture_" + id, {
      type: "image/jpeg",
    });

    uploadFile(file, uid, uid, "")
      .then((res) => {
        let pathuri = res.data?.url;
        setInspectionData({
          ...inspectionData,
          image_list: [...inspectionData?.image_list, pathuri],
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateInspection = () => {
    let tdata = {
      image_capture: inspectionData.capture,
      image_list: inspectionData.image_list,
      video_list: inspectionData.video_list,
      file_list: inspectionData.file_list,
      description: inspectionData.description,
    };
    setLoading(true);
    updatePackageInspection(id, tdata)
      .then((pr) => {
        handleSuccess("Successfully updated");

        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUploads = (up_type, files) => {
    let flinks = [];
    let flen = files.length;
    setLoading(true);
    for (let f = 0; f < flen; f++) {
      uploadFile(files[f], uid, uid, "")
        .then((res) => {
          let pathuri = res.data?.url;
          flinks.push(pathuri);

          if (f === flen - 1) {
            setInspectionData({
              ...inspectionData,
              [up_type + ""]: [...inspectionData?.[up_type + ""], ...flinks],
            });
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getShipmentInspection(id)
      .then((res) => {
        setInspectionData({
          capture: res.data?.data?.image_capture || "",
          description: res.data?.data?.description || "",
          file_list: res.data?.data?.file_list || [],
          image_list: res.data?.data?.image_list || [],
          video_list: res.data?.data?.video_list || [],
        });
      })
      .catch((e) => console.log(e));
  }, [id]);

  const [blobData, setBlobData] = useState({ type: null, data: null });
  const [showPdf, setShowPdf] = useState(false);

  const viewFile = (url) => {
    const utoken = "Bearer " + token;
    const header = {
      Authorization: utoken,
      "Content-Type": "application/json",
    };

    axios
      .get(url + "", { responseType: "blob", headers: header })
      .then((res) => {
        const contentType = res.data?.type;
        setBlobData({ type: contentType, data: res.data });
        setShowPdf(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          height: "70px",
          zIndex: 9999,
          width: { sm: "100%", xs: "205%", position: "fixed", top: 0, left: 0 },
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ mt: 4 }}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", p: 1 }}
            >
              <Typography>Package Details</Typography>

              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "7px",
                    px: "10px",
                    textTransform: "none",
                    color: "#1e5267",
                    border: "1px solid #1e5267",
                    ":hover": { border: "1px solid #1e5267" },
                  }}
                  onClick={() => {
                    updateInspection();
                  }}
                  disabled={loading}
                >
                  Update Details
                </Button>
              </Box>
            </Box>
            <Box sx={{ backgroundColor: "#fff", p: 2 }}>
              {loading && <LinearProgress />}
              <Box>
                <Typography>Description</Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextInputComponent
                  placeholder="Enter a small description"
                  rows={3}
                  value={inspectionData.description}
                  handleChange={(e) => {
                    setInspectionData({
                      ...inspectionData,
                      description: e.target.value,
                    });
                  }}
                />
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography sx={{ borderBottom: "1px solid #ccc", mb: 5 }}>
                  Image Capture / Upload (optional)
                </Typography>
                <Box
                  sx={{ display: "flex", pb: 5, alignItems: "start", gap: 2 }}
                >
                  <Box sx={{ width: "400px", minWidth: "max-content" }}>
                    <Box>
                      <Box sx={{ width: "100%" }}>
                        <WebcamCapture
                          imagec={capture}
                          name={"Package"}
                          handleCaptureImage={(e) => {
                            handleCaptureImage(e);
                          }}
                          instruction={true}
                        />
                      </Box>

                      <Box sx={{ mt: 2, width: "100%" }}>
                        <FileInput
                          bgColor="#f2f2f2"
                          textColor="#000"
                          uploadlabel="Upload Images"
                          acc="image/*"
                          handleChange={(e) => {
                            handleUploads("image_list", e.target.files);
                          }}
                          idn={"upload-image-package"}
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ width: "100%" }}>
                    {inspectionData?.image_list?.length > 0 && (
                      <Grid container>
                        {inspectionData?.image_list?.map((il, ilk) => (
                          <Grid item xs={12} sm={6} lg={4} key={il + "" + ilk}>
                            <Box
                              sx={{
                                width: "100%",
                                height: "100%",
                                overflow: "hidden",
                              }}
                            >
                              <img
                                src={il + "?Authorization=Bearer " + token}
                                alt="package img"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ borderBottom: "1px solid #ccc", mb: 5 }}>
                  <Typography>Videos (optional)</Typography>
                </Box>
                <Box sx={{ mb: 5, width: "300px" }}>
                  <FileInput
                    bgColor="#f2f2f2"
                    textColor="#000"
                    acc="video/*"
                    uploadlabel="Upload Videos"
                    handleChange={(e) => {
                      handleUploads("video_list", e.target.files);
                    }}
                    idn={"upload-video-package"}
                  />
                </Box>

                <Box sx={{}}>
                  <Typography>Files (optional)</Typography>
                </Box>
                <Box sx={{ borderTop: "1px solid #ccc" }}>
                  {inspectionData?.file_list?.length > 0 && (
                    <Box
                      sx={{
                        my: 5,
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 1,
                      }}
                    >
                      {inspectionData?.file_list?.map((ifl, iflk) => (
                        <Box
                          key={iflk}
                          sx={{
                            mb: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                            p: 1,
                          }}
                        >
                          <Box
                            sx={{
                              maxWidth: "350px",
                              wordBreak: "break-all",
                            }}
                          >
                            <Typography>
                              {ifl?.replace(
                                "https://xmi.xnacloud.com/download/xmilab_be/",
                                ""
                              )}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              alignItems: "start",
                            }}
                          >
                            <Box
                              sx={{
                                mr: 1,
                                cursor: "pointer",
                                color: "#0000EE",
                                border: "2px solid",
                                px: 1,
                                borderRadius: "7px",
                                ":hover": { color: "#6666ff" },
                              }}
                              onClick={() => {
                                viewFile(ifl);
                              }}
                            >
                              View
                            </Box>
                            <a
                              href={ifl + "?Authorization=Bearer " + token}
                              download
                            >
                              Download
                            </a>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  )}

                  <Box sx={{ width: "300px" }}>
                    <FileInput
                      bgColor="#f2f2f2"
                      textColor="#000"
                      uploadlabel="Upload Files"
                      handleChange={(e) => {
                        handleUploads("file_list", e.target.files);
                      }}
                      idn={"upload-file-package"}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        maxWidth={"md"}
        open={showPdf}
        onClose={() => {
          setShowPdf(false);
        }}
      >
        <DialogContent>
          <Box>
            {blobData?.type === "application/pdf" ? (
              <PdfViewer url={blobData.data} />
            ) : blobData.type?.startsWith("image/") ? (
              <img
                src={URL.createObjectURL(blobData.data)}
                style={{ width: "100%" }}
                alt=""
              />
            ) : (
              <Box>
                <pre>{JSON.stringify(blobData.data)}</pre>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default PackageInspection;
