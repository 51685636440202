import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(({ theme }) => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ccc",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ccc",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ccc",
    },
    "& input::placeholder": {
      fontSize: "16px",
    },
  },

  sminput: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ccc",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ccc",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ccc",
    },
    "& input::placeholder": {
      fontSize: "16px",
    },
    "& input": {
      height: "10px",
      backgroundColor: "#fff",
    },
  },
}));

function TextInputComponent({
  placeholder,
  value = "",
  handleChange,
  rows = 1,
  smheight = true,
  type = "text",
  disabled = false,
}) {
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      className={smheight ? classes.root : classes.sminput}
      variant="outlined"
      type={type}
      placeholder={placeholder}
      multiline={rows > 1}
      rows={rows}
      value={value}
      onChange={handleChange}
      size={"small"}
      disabled={disabled}
      style={
        {
          // backgroundColor:"#fff",
          // height:height
        }
      }
    />
  );
}

TextInputComponent.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  rows: PropTypes.number,
};

export default TextInputComponent;
