import React, { useEffect, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import { drxnaUrl } from "../services/url.service";
import HttpService from "../services/http.service";

import {
  fetchSampleById,
  fetchSampleInspection,
  fetchSampleInspectionOut,
  fetchShipmentInspection,
  fetchShipmentInspectionOut,
} from "../services/instruments.service";

const ProgressTimeline = ({
  data,
  orderid,
  payload,
  datalog = [],
  psample = [],
}) => {
  const token = localStorage.getItem("usertoken");
  const [progressDetail, setProgressDetail] = useState(data);
  const [shipmentDetails, setShipmentDetails] = useState({
    shipment: [],
    sample_data: {},
    id: "",
  });
  const [isCompleted, setIsCompleted] = useState(
    data[data?.length - 1]?.is_finished
  );
  // get actions
  const getActions = () => {
    HttpService.post(
      drxnaUrl + "/workflow/order/" + orderid + "/action?as_minion=false",
      payload
    )
      .then((res) => {
        setProgressDetail(
          res?.data?.content?.unformatted_data?.progress_list_status
        );
        setIsCompleted(
          res?.data?.content?.unformatted_data?.progress_list_status[
            data?.length - 1
          ]?.is_finished
        );
      })
      .catch((err) => console.log(err));
  };

  function replaceUnderscores(str) {
    if (str.includes("_")) {
      return str.replace(/_/g, " ");
    } else {
      return str;
    }
  }

  function capitalizeFirstLetter(str) {
    if (str.length > 0) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return "";
    }
  }

  const [stepDetail, setStepDetail] = useState({
    stepNo: "",
    stepName: "",
    status: "",
  });

  const [selectedStep, setSelectedStep] = useState("");

  const getShipmentDetail = async () => {
    let sample_in_shipment = psample?.[0];
    let shipment_id = "";
    if (sample_in_shipment && shipmentDetails.id == "") {
      const fetchShipmentId = await fetchSampleById(sample_in_shipment);
      shipment_id = fetchShipmentId?.data?.shipment_id;
      const dataArray = [];

      if (shipment_id) {
        const fetchShipmentOutbound = await fetchShipmentInspectionOut(
          shipment_id
        );
        dataArray.push(fetchShipmentOutbound.data?.data);
        const fetchSampleOutbound = await fetchSampleInspectionOut(
          shipment_id,
          sample_in_shipment
        );
        dataArray.push(fetchSampleOutbound.data?.data);
        const fetchShipmentInbound = await fetchShipmentInspection(shipment_id);
        dataArray.push(fetchShipmentInbound.data?.data);
        const fetchSampleInbound = await fetchSampleInspection(
          shipment_id,
          sample_in_shipment
        );
        dataArray.push(fetchSampleInbound.data?.data);

        setShipmentDetails({
          shipment: dataArray,
          sample_data: fetchSampleInbound.data.data?.sample_data,
          id: fetchSampleInbound.data.data?._id,
        });
      }
    }
  };

  const populateAddedForm = (form_name) => {
    let populatedForm = (
      <Box sx={{ fontSize: "24px", fontWeight: "700" }}>-</Box>
    );
    if (!datalog || datalog?.length == 0) return populatedForm;

    if (datalog?.data?.name && form_name == "sample_form") {
      let form_content = datalog.data?.content?.blocks?.[0]?.data?.content;
      populatedForm = (
        <Box sx={{ fontFamily: "Open Sans" }}>
          {form_content.map((fc) => (
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box>{fc?.[0] + ": "}</Box>
              <Box>{fc?.[1]}</Box>
            </Box>
          ))}
        </Box>
      );
    } else if (Array.isArray(datalog)) {
      let log_form = datalog?.find((dl) => dl.progress == form_name);
      if (form_name == "sample_shipment") getShipmentDetail();
      if (log_form) {
        let form_content =
          log_form.content?.content?.blocks?.[0]?.data?.content;
        populatedForm = (
          <Box sx={{ fontFamily: "Open Sans" }}>
            {form_content.map((fc) => (
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box>{fc?.[0] + ": "}</Box>
                <Box>{fc?.[1]}</Box>
              </Box>
            ))}
          </Box>
        );
      }
    }

    return populatedForm;
  };

  useEffect(() => {
    for (let i = 0; i < progressDetail?.length; i++) {
      if (
        progressDetail[i - 1]?.is_finished &&
        !progressDetail[i]?.is_finished
      ) {
        if (selectedStep == "") {
          setSelectedStep(i + 1);
          setStepDetail({
            stepNo: i + 1,
            stepName: progressDetail[i]?.progress_name,
            status: progressDetail[i]?.is_finished
              ? "Completed"
              : progressDetail[i - 1]?.is_finished
              ? "In Progress"
              : "Pending",
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressDetail]);

  useEffect(() => {
    const poll = setInterval(() => {
      getActions();

      if (isCompleted) {
        clearInterval(poll);
      }
    }, 4000);

    return () => clearInterval(poll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompleted]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "100px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {progressDetail?.map((step, index) => (
          <div
            key={index}
            style={{
              textAlign: "center",
              position: "relative",
              display: "flex",
              alignItems: "center",
              gap: "0px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  onClick={() => {
                    setSelectedStep(index + 1);
                    setStepDetail({
                      stepNo: index + 1,
                      stepName: progressDetail[index]?.progress_name,
                      status: progressDetail[index]?.is_finished
                        ? "Completed"
                        : progressDetail[index - 1]?.is_finished
                        ? "In Progress"
                        : "Pending",
                    });
                  }}
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "8px",
                    border: step?.is_finished
                      ? "2px solid #22c55e"
                      : progressDetail[index - 1]?.is_finished
                      ? "2px solid skyblue"
                      : "2px solid #ef4444",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                    position: "relative",
                    zIndex: 1,
                    color: step?.is_finished
                      ? "#22c55e"
                      : progressDetail[index - 1]?.is_finished
                      ? "skyblue"
                      : "#ef4444",
                    cursor: "pointer",
                    background: selectedStep === index + 1 ? "#dbeafe" : "",
                  }}
                >
                  {index + 1}
                  {progressDetail[index - 1]?.is_finished &&
                    !step?.is_finished && (
                      <div
                        style={{
                          width: "80px",
                          height: "80px",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <SettingsIcon
                          sx={{
                            fontSize: "70px",
                            animation: "spin 3s linear infinite",
                            "@keyframes spin": {
                              from: {
                                transform: "rotate(0deg)",
                              },
                              to: {
                                transform: "rotate(360deg)",
                              },
                            },
                          }}
                        />
                      </div>
                    )}
                </div>
                <div
                  style={{
                    fontSize: "12.5px",
                    position: "absolute",
                    top: "85px",
                    color: "black",
                    fontWeight:
                      progressDetail[index - 1]?.is_finished &&
                      !step?.is_finished
                        ? "700"
                        : "500",
                  }}
                >
                  {capitalizeFirstLetter(step?.progress_name)}
                </div>
              </div>
            </div>
            {index !== progressDetail?.length - 1 && (
              <div
                style={{
                  width: "60px",
                  height: 10,
                  backgroundColor: step?.is_finished ? "#22c55e" : "#ef4444",
                  zIndex: 2,
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: "14px",
                    height: "14px",
                    borderRadius: "7px",
                    background: "black",
                    position: "absolute",
                    left: "-8px",
                    top: "-2px",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: "14px",
                    height: "14px",
                    borderRadius: "7px",
                    background: "black",
                    position: "absolute",
                    right: "-8px",
                    top: "-2px",
                  }}
                ></Box>
              </div>
            )}
          </div>
        ))}
      </div>
      <Box
        sx={{ display: "flex", flexDirection: "column", textAlign: "start" }}
      >
        <Typography
          sx={{ fontFamily: "Open Sans", color: "black", fontSize: "18px" }}
        >
          Details
        </Typography>
        <div
          style={{
            width: "100%",
            borderTop: "1px solid #ccc",
            textAlign: "start",
            display: "flex",
            flexDirection: "column",
            py: "10px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1, py: 2 }}>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography sx={{ fontFamily: "Open Sans" }}>
                Step Number:
              </Typography>
              <Typography sx={{ fontFamily: "Open Sans" }}>
                {stepDetail?.stepNo}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography sx={{ fontFamily: "Open Sans" }}>
                Step Name:
              </Typography>
              <Typography sx={{ fontFamily: "Open Sans" }}>
                {replaceUnderscores(stepDetail?.stepName)}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography sx={{ fontFamily: "Open Sans" }}> Status:</Typography>
              <Box
                sx={{
                  border:
                    stepDetail?.status === "Completed"
                      ? "1px solid #22c55e"
                      : stepDetail?.status === "Pending"
                      ? "1px solid #ef4444"
                      : "1px solid skyblue",
                  px: "10px",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Open Sans",
                    color:
                      stepDetail?.status === "Completed"
                        ? "#22c55e"
                        : stepDetail?.status === "Pending"
                        ? "#ef4444"
                        : "skyblue",
                  }}
                >
                  {stepDetail?.status}
                </Typography>
              </Box>
            </Box>
            <Box>
              {stepDetail?.stepName !== "sample_shipment" && (
                <Typography
                  sx={{
                    fontFamily: "Open Sans",
                    color: "black",
                    fontSize: "18px",
                    my: 2,
                  }}
                >
                  Pre Populated Data
                </Typography>
              )}
              {populateAddedForm(stepDetail?.stepName)}
            </Box>
            {stepDetail?.stepName == "sample_shipment" && (
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Open Sans",
                    color: "black",
                    fontSize: "18px",
                    my: 2,
                  }}
                >
                  Sample Inspection Data
                </Typography>
                <Box>
                  {shipmentDetails.sample_data?.sample_id && (
                    <Box sx={{ my: 2 }}>
                      <Typography sx={{ fontFamily: "Open Sans" }}>
                        Sample Name:{" "}
                        {shipmentDetails.sample_data["sample_name"]}
                      </Typography>
                      <Typography sx={{ fontFamily: "Open Sans" }}>
                        Sample ID: {shipmentDetails.sample_data["sample_id"]}
                      </Typography>
                    </Box>
                  )}
                  <Box>
                    {["sh_out", "out", "sh_in", "in"]?.map((tkey, ti) => (
                      <Accordion
                        key={"t" + tkey}
                        sx={{ mb: 1 }}
                        onChange={(e, x) => {
                          console.log("c", x, ti);
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id={"panel1a-header" + tkey}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Open Sans",
                              color: "black",
                              fontSize: "16px",
                              fontWeight: "600",
                            }}
                          >
                            {tkey == "sh_out"
                              ? "Shipment Inspection Before Delivery"
                              : tkey == "out"
                              ? "Sample Inspection Before Delivery"
                              : tkey == "sh_in"
                              ? "Shipment Inspection After Delivery"
                              : "Sample Inspection After Delivery"}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {shipmentDetails.shipment.length > 0 ? (
                            <Box>
                              <Box>
                                {"Description - "}
                                {shipmentDetails.shipment[ti]?.description ||
                                  ""}
                              </Box>
                              <Box>
                                {shipmentDetails.shipment[ti]
                                  ?.image_capture && (
                                  <img
                                    src={
                                      shipmentDetails.shipment[ti]
                                        ?.image_capture +
                                      "?Authorization=Bearer " +
                                      token
                                    }
                                    alt="sample img"
                                    width={"auto"}
                                  />
                                )}
                                {shipmentDetails.shipment[ti]?.image_list
                                  .length > 0 && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      alignItems: "center",
                                    }}
                                  >
                                    {shipmentDetails.shipment[
                                      ti
                                    ]?.image_list?.map((il, ilk) => (
                                      <Box
                                        sx={{ width: "100px", mr: 1 }}
                                        key={il + "" + ilk}
                                      >
                                        <img
                                          src={
                                            il +
                                            "?Authorization=Bearer " +
                                            token
                                          }
                                          alt="package img"
                                          width={"100%"}
                                        />
                                      </Box>
                                    ))}
                                  </Box>
                                )}
                                {shipmentDetails.shipment[ti]?.file_list
                                  .length > 0 && (
                                  <Box sx={{ my: 1 }}>
                                    {shipmentDetails.shipment[
                                      ti
                                    ]?.file_list.map((ifl, iflk) => (
                                      <Box
                                        key={iflk}
                                        sx={{
                                          mb: 1,
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontFamily: "Open Sans",
                                            color: "black",
                                            fontSize: "14px",
                                            my: 2,
                                          }}
                                        >
                                          {"• " +
                                            ifl?.replace(
                                              "https://xmi.xnacloud.com/download/xmilab_be/",
                                              ""
                                            )}
                                        </Typography>
                                        <Box>
                                          <a
                                            href={
                                              ifl +
                                              "?Authorization=Bearer " +
                                              token
                                            }
                                            download
                                          >
                                            Download
                                          </a>
                                        </Box>
                                      </Box>
                                    ))}
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          ) : (
                            <Box sx={{ fontWeight: "700", fontSize: "30px" }}>
                              &#8212;
                            </Box>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </Box>
    </div>
  );
};

export default ProgressTimeline;
